.flex-table {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    background: #fff;

}
.flex-table thead {
    color: #fff;
    background: #27353b;

}
.flex-table thead tr,
.flex-table tbody tr {
    display: flex;

}
.flex-table tbody tr+tr {
    border-top: 1px solid #ccc;

}
.flex-table thead tr th,
.flex-table tbody tr td {
    display: flex;
    flex: 1;
    padding: .5em;
}
@media screen and (max-width: 640px) {
    .flex-table {
        border: 0;
    }
    .flex-table thead {
        display: none;
    }
    .flex-table tbody tr {
        flex-direction: column;
        margin: 1em;
        border: 1px solid #ccc;
    }
    .flex-table tbody tr td {
        flex-direction: column;
    }
    .flex-table tbody tr td+td {
        border-top: 1px solid #ccc;
    }
    .flex-table tbody tr td:before {
        display: flex;
        margin: -.5em -.5em .75em -.5em;
        padding: .5em;
        content: attr(data-label);
        color: #fff;
        background: #27353b;
    }
}